import styled, { css } from 'styled-components'

export const HouseStyleExceptionsStyled = styled('div')(
  ({ theme }) => css`
    ul {
      margin: ${theme.spacing(0, 0, 4, 0)};
      padding: 0;
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        column-gap: ${theme.spacing(2)};
        margin: ${theme.spacing(1, 0)};
      }
    }

    form {
      display: grid;
      grid-template-columns: 1fr 1fr min-content;
      align-items: center;
      column-gap: ${theme.spacing(4)};

      button {
        white-space: nowrap;
      }
    }
  `
)
