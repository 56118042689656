import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { PriceNumber } from '@api/endpoints/types'
import {
  HouseStyleExceptionId,
  useCreateHouseStyleException,
  useDeleteHouseStyleException,
  useGetHouseStyleExceptions
} from '@api/endpoints/buildings/houses'
import { LayoutTypeId, LayoutTypeStyle, LayoutTypeStyleId } from '@api/endpoints/buildings/layoutTypes'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Delete } from '@mui/icons-material'
import { Box, CircularProgress } from '@mui/material'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Form, FormSelectField, FormTextField, useFormTyped } from '@controls/Form'
import { MenuItemEntry } from '@controls/types'
import { useHouseFormContext } from '../HouseFormContext'
import { HouseStyleExceptionsStyled } from './HouseStyleExceptions.styles'

const HouseStyleExceptionsFormValues = z.object({
  layoutTypeStyleId: LayoutTypeId,
  additionalPrice: PriceNumber
})
type HouseStyleExceptionsFormValues = z.infer<typeof HouseStyleExceptionsFormValues>

export const HouseStyleExceptions: React.FC = () => {
  const { t } = useTranslation()
  const { house, layoutType } = useHouseFormContext()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const deleteStyleException = useDeleteHouseStyleException({ onError: defaultErrorHandler })
  const createStyleException = useCreateHouseStyleException({ onError: defaultErrorHandler })
  const getStyleExceptions = useGetHouseStyleExceptions(house.id, { onError: defaultErrorHandler })

  const onDelete =
    (houseStyleExceptionId: HouseStyleExceptionId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteStyleException.mutate({ houseStyleExceptionId, houseId: house.id })
      }
    }

  const onSubmit = (values: HouseStyleExceptionsFormValues): void => {
    createStyleException.mutate({ ...values, houseId: house.id }, { onSuccess: () => form.reset() })
  }

  const form = useFormTyped({
    resolver: HouseStyleExceptionsFormValues,
    onSubmit
  })

  const layoutTypeOptions: ReadonlyArray<MenuItemEntry<LayoutTypeStyleId>> = React.useMemo(
    () =>
      layoutType?.styles
        .filter(
          (style) => !getStyleExceptions.data?.some((styleException) => style.id === styleException.layoutTypeStyleId)
        )
        .map((l) => ({ label: l.styleType, value: l.id })) ?? [],
    [layoutType, getStyleExceptions.data]
  )

  const getLayoutTypeStyle = (id: LayoutTypeStyleId): LayoutTypeStyle | undefined => {
    return layoutType?.styles.find((s) => s.id === id)
  }

  const isFormDisabled = false //useMemo(() => !isEditableEntity(house.allowedActions), [house])
  //const isDeleteDisabled = useMemo(() => !isDeletableEntity(house.allowedActions), [house])

  const isLoading = getStyleExceptions.isFetching

  return (
    <HouseStyleExceptionsStyled>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {Boolean(getStyleExceptions.data?.length) && (
            <ul>
              {getStyleExceptions.data?.map((e) => (
                <li key={e.id}>
                  <ButtonWithConfirmation
                    color="error"
                    disabled={isFormDisabled}
                    onConfirmResult={onDelete(e.id)}
                    confirmTitle={t('global.consent.delete.title')}
                    confirmMessage={t('global.consent.delete.message')}
                  >
                    <Delete />
                  </ButtonWithConfirmation>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <div>{getLayoutTypeStyle(e.layoutTypeStyleId)?.styleType}</div>
                    <div>{e.additionalPrice}</div>
                  </Box>
                </li>
              ))}
            </ul>
          )}
          {layoutTypeOptions.length > 0 && (
            <Form f={form}>
              <FormSelectField
                name={form.names.layoutTypeStyleId}
                options={layoutTypeOptions}
                label={t('pages.admin.house.style_exceptions.form.field.layout_type_style')}
                disabled={isFormDisabled}
              />
              <FormTextField
                name={form.names.additionalPrice}
                type="number"
                label={t('pages.admin.house.style_exceptions.form.field.additional_price')}
                disabled={isFormDisabled}
              />
              <Button type="submit" disabled={isFormDisabled}>
                {t('pages.admin.house.style_exceptions.buttons.add')}
              </Button>
            </Form>
          )}
          {layoutTypeOptions.length === 0 &&
            getStyleExceptions.data?.length === 0 &&
            t('pages.admin.house.style_exceptions.messages.no_items')}
        </>
      )}
    </HouseStyleExceptionsStyled>
  )
}
